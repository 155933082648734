body {
  background-color: #5baefb;
}

.grid-container {
  margin: 6vw;
  margin-top: 0;
}

.graph-container {
  position: relative;
  margin: auto;
  width: 60vw;
}

.grid-item {
  padding: 1vh;
  font-size: 30px;
  text-align: center;
}

.time-bar {
  padding-top: 10vh;
  padding-bottom: 50px;
}

.timeScale {
  display: flex;
  flex-direction: row;
  width: 100vw;
  font-size: small;
}

.start-task-button {
  background: #334e68;
  color: #cfcfcf;
  width: 40vw;
  border-radius: 5px;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
  cursor: pointer;
}

.legend-button {
  background: #334e68;
  color: #cfcfcf;
  border-radius: 5px;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
}

.legend-popup-button {
  background: #334e68;
  color: #cfcfcf;
  border-radius: 5px;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  margin-left: 0.75em;
  margin-top: 0.75em;
}

.start-task-button:hover {
  transform: scale(1.1);
}

.button-container {
  margin-bottom: 0vh;
}

.runnig-task-button {
  background: rgb(31, 255, 35);
  font-size: 45px;
  border-radius: 5px;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
}

.barchart {
  margin-top: 5vh;
  margin-right: 5vw;
}

@media only screen and (min-width: 1080px) {
  .barchart {
    margin-top: 5vh;
    margin-right: 5vw;
  }
  .button-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: center;
    text-align: center;
    height: 60vh;
  }

  .grid-container {
    height: 20vh;
  }

  .start-task-button {
    width: 20vw;
  }
}

@media only screen and (min-width: 1650px) {
  .grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    margin: 10px;
  }
  .grid-container {
    height: 20vh;
    margin-top: 10vh;
  }
  .grid-item {
    padding: 20px;
    height: 5vh;
  }
  .start-task-button {
    width: 15vw;
  }
}
