.nav-bar {
  /* background: rgba(255, 255, 255, 0.8); */
  background: #f7f7f780;
  backdrop-filter: blur(10px);
  position: fixed;
  width: 100vw;
  z-index: 100;
}

.nav-bar .icon {
  height: 5vh;
}

.nav-bar .logo-nav {
  text-decoration: none;
  font-weight: bold;
  color: #000;
  display: flex;
}

.nav-bar .title {
  margin: 0;
  float: left;
  font-size: 2em;
  padding: 1vh;
}

.nav-bar ul {
  list-style: none;
  margin: 0;
  float: right;
}

.nav-bar .logo-nav span {
  color: var(--primary-color);
}

.nav-bar nav {
  display: flex;
  align-items: center;
}

.nav-bar .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  place-content: space-between;
}

.nav-bar .mobile-menu {
  cursor: pointer;
}
.mobile-menu,
.mobile-menu-exit {
  display: none;
}
.nav-bar .container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 180px auto;
  grid-template-columns: 180px auto;
  -webkit-box-pack: unset;
  -ms-flex-pack: unset;
  justify-content: unset;
}
.nav-bar nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: flex-end;
  background: none;
  height: auto;
}
.nav-bar nav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.nav-bar nav a {
  color: #000;
  padding: 0.1em 1em;
  font-size: 1em;
}
.nav-bar ul.primary-nav {
  margin: 0;
}
.nav-bar li.current a {
  font-weight: bold;
}
.nav-bar li.go-premium-cta a {
  color: #fff;
  background: linear-gradient(265deg, #3545da 5%, #144b98 220%);
  padding: 0.4em 1.4em;
  border-radius: 1em;
  font-weight: bold;
  margin-top: -0.2em;
  margin-left: 1em;
}

@media only screen and (max-width: 600px) {
  .nav-bar nav {
    justify-content: center;
  }
  .nav-bar .title {
    font-size: 0;
    vertical-align: middle;
    visibility: hidden;
    padding: 0;
  }
}

