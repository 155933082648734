.name {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 0.5em;
    text-align: center;
}

.container {
    margin-bottom: 10vh;
}

.timeScale {
    display: flex;
    flex-direction: row;
    width: 100vw;
    font-size: small;
}

@media only screen and (max-width: 600px) {
    .container {
        margin-bottom: 0;
    }
}