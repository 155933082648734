.name {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 1em;
    margin-top: 5vh;
    text-align: center;
}

.date {
    font-size: 1.2em;
    margin-top: 5vh;
    text-align: center;
}