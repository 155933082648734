@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap");

/* --- MAIN STYLE ---*/
:root {
  --primary-color: "#1f2134";
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23344154' fill-opacity='0.53' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E"); */
}

a {
  color: #444;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

section {
  padding: 3em 0em;
}

h1 {
  padding: 0em 1em 0em;
}

/* --- HERO STYLE ---*/
.hero {
  background: #f7f7f7;
  text-align: center;
  color: #114695;
  height: 80vh;
}

.left-col h1 {
  padding-top: 10vh;
  text-transform: capitalize;
  font-size: 4em;
  font-weight: bolder;
  margin-top: 0.1em;
  line-height: 1.3em;
}

.left-col h3 {
  text-transform: uppercase;
  padding-left: 20vw;
  padding-right: 20vw;
  font-weight: bold;
  letter-spacing: 3px;
  font-size: 1em;
  align-self: center;
}

.primery-cta {
  background: linear-gradient(265deg, #3545da 5%, #144b98 220%);
  color: #fff;
  text-decoration: none;
  padding: 0.6em 1.3em;
  font-size: 1.4em;
  border-radius: 1em;
  margin-top: 1em;
  font-weight: bold;
  display: inline-block;
  box-shadow: 0px 0px 40px 20px #0ff;
}

.primery-cta:hover {
  scale: 1.1;
}

.watch-video-cta {
  display: block;
  margin-top: 1em;
}

.watch-video-cta img {
  display: line-block;
  margin-top: -0.5em;
  margin-right: 0.5em;
  vertical-align: middle;
  width: 2.5em;
}

.hero-img {
  height: 40vh;
  margin-top: 3em;
}

.history-container {
  background: #f7f7f7;
  text-align: center;
  color: #114695;

}

.histoty-title {
  text-transform: capitalize;
  font-size: 2.5em;
  font-weight: bolder;
  margin-top: 0.1em;
  line-height: 1.3em;
}

.history-img {
  width: 80vw;
  border-radius: 3em;
}

/* --- FEATURES STYLE ---*/
.features-section {
  background: #5baefb;
}

.features-section ul {
  margin-top: 10vh;
  padding-left: 0.1em;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(19rem, 1fr)) [auto-fit];
  grid-template-columns: repeat(auto-fit, minmax(19rem, 1fr));
}

.features-section li {
  font-size: 1.1em;
  margin-bottom: 1em;
  margin-left: 2em;
  position: relative;
}

.features-section h1 {
  font-size: 2em;
  font-weight: 800;
  margin-bottom: 1em;
  text-align: center;

}

.features-tile-container {
  margin-top: 5em;
  flex-direction: row;
  display: flex;
  justify-content: center;
  width: 100%;
}

.features-tile-img {
  border-radius: 0.5em;
  display: block;
}

.features-tile-img-desktop-only {
  border-radius: 0.5em;
  display: block;
}

.features-tile-text-container {
  margin-left: 2em;
  margin-right: 2em;
  margin-top: 1em;
}

.features-tile-text-container h2 {
  padding-top: 10vh;
  font-size: 32px;
  overflow-wrap: break-word;
  width: 35vw;
  font-weight: 800;
}

.features-tile-text-container h3 {
  overflow-wrap: break-word;
  width: 35vw;
  font-weight: 500;
}

/* --- TEST STYLE ---*/
.test-monials-section {
  background: rgba(255, 255, 255, 0.8);
  color: #fff;
}

.test-monials-section img {
  width: 50%;
  height: 30%;
  border: 5px solid #0067d6b9;
  border-radius: 5em;
  margin-top: -4.5em;
  background-size: cover;
}

.test-monials-section li {
  background: #2083ee42;
  text-align: center;
  padding: 2em 1em;
  width: 80%;
  margin: 0 auto 5em auto;
  border-radius: 1em;
}

/* --- CONTACT STYLE ---*/
h2 {
  font-size: 2em;
}

label {
  display: block;
  font-size: 1.2em;
  margin-bottom: 0.5em;
}

input,
textarea {
  width: 100%;
  padding: 0.8em;
  margin-bottom: 1em;
  border-radius: 0.3em;
  border: 1px solid gray;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

input[type="submit"] {
  background-color: var(--primary-color);
  color: #fff;
  text-decoration: none;
  font-size: 1.3em;
  border-radius: 5em;
  font-weight: bold;
  display: inline-block;
  margin-bottom: 5em;
  padding: 0.8em 2em;
  cursor: pointer;
}

iframe {
  width: 100%;
  height: 300px;
}

nav.menu-btn {
  display: block;
}

nav {
  z-index: 999;
  right: 1em;
  top: 0;
  height: 50vh;
  padding: 1em;
}

nav ul.primary-nav {
  margin-top: 4em;
  float: right;
}

nav ul.second-nav {
  float: right;
}

nav li a {
  color: #fff;
  text-decoration: none;
  display: block;
  padding: 1em;
  text-align: right;
  -webkit-transition: font-weight 0.2s;
  transition: font-weight 0.2s;
}

.mobile-menu-exit {
  display: block;
  float: right;
  margin: 0.5em;
  cursor: pointer;
}


@media only screen and (max-width: 600px) {

  .left-col h1 {
    padding-top: 10vh;
    text-transform: capitalize;
    font-size: 3em;
    font-weight: bolder;
    margin-top: 0.1em;
    line-height: 1.3em;
  }

  .hero-img {
    width: 80vw;
    height: auto;
    margin-top: 3em;
  }

  .features-tile-container {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }

  .features-tile-img {
    width: 60vw;
    margin-top: 2em;
  }

  .features-tile-img-desktop-only {
    width: 0;
    visibility: hidden;
  }

  .features-tile-text-container h2 {
    width: 90vw;
    padding-top: 0;
  }

  .features-tile-text-container h3 {
    width: 90vw;
  }

  .histoty-title {
    text-transform: capitalize;
    font-size: 2em;
    font-weight: bolder;
    margin-top: 0.1em;
    line-height: 1.3em;
  }

  .history-img {
    width: 90vw;
    border-radius: 0.3em;
  }

}
